import './academia.scss';

import React from 'react';

import Layout from 'components/layouts/Standard';

const AcademiaPage: React.FC = () => {
  return (
    <Layout
      title="Academia &mdash; Friends of The Web"
      tagline="is a small digital agency in Baltimore, Maryland."
    >
      <div className="academia-content">
        <h2>
          Friends of The Web is helping research labs, graduate programs, and
          other teams from colleges and universities effectively communicate
          their research to a broader audience. We’ll work closely with you to
          understand the nature of your work and use our design and development
          expertise to help share the knowledge you’ve gained with people that
          can put it to good use.
        </h2>

        <div className="academia-content-contact">
          Email{' '}
          <a
            className="academia-content-contact-link"
            href="mailto:&#104;&#101;&#108;&#108;&#111;&#64;&#102;&#114;&#105;&#101;&#110;&#100;&#115;&#111;&#102;&#116;&#104;&#101;&#119;&#101;&#98;&#46;&#99;&#111;&#109;"
          >
            &#104;&#101;&#108;&#108;&#111;&#64;&#102;&#114;&#105;&#101;&#110;&#100;&#115;&#111;&#102;&#116;&#104;&#101;&#119;&#101;&#98;&#46;&#99;&#111;&#109;
          </a>{' '}
          to discuss your project.
        </div>
        <h3>Web Design and Development</h3>
        <p>
          Instead of starting with a theme or template and trying to fit your
          content into it, we begin with your body of work and design a custom
          site that suits your specific research. All of the websites we build
          are fully responsive and adhere to the highest standards of
          maintainability, security, and accessibility. We take advantage of
          open source software like D3, React, Gatsby, and Ruby on Rails to save
          time and ensure that all our work is in line with industry best
          practices.
        </p>
        <h3>Data Visualization</h3>
        <p>
          Work with our team of design and infographic experts to select
          visualizations that portray your data faithfully and effectively, or,
          if appropriate, design completely new visual tools specific to your
          needs. We have experience designing static graphics for PDFs,
          presentations, and printed reports, and interactive tools for
          exploring data on the web with D3.
        </p>
        <h3>Content Strategy</h3>
        <p>
          Developing a content strategy from the start of your project will
          provide a framework for identifying, organizing, and planning your
          content: data visualizations, text, images, videos, and more. We will
          work with you to perform an audit of existing content and propose new
          content where necessary, all in alignment with your goals.
        </p>
        <h3>Copy Writing</h3>
        <p>
          We can craft clear, compelling website copy that helps your visitors
          navigate your site quickly and understand your research.
        </p>
        <h3>Selected Partners</h3>
        <ul>
          <li>Harvard University’s EASEL Lab</li>
          <li>John’s Hopkins School of Advanced International Studies</li>
          <li>University of San Diego</li>
          <li>Fordham University</li>
          <li>Maryland Institute College of Art</li>
        </ul>
      </div>
    </Layout>
  );
};

export default AcademiaPage;
